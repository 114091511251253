@import '../../../variables.module.scss';

.createCardContainer {
  border-radius: 12px;
  width: 352px;
  background-color: white;
  border-color: $neutral700;
  border-style: dashed;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 230px;

  &:hover {
    background-color: $neutral100;
  }

  @include mobile {
    min-height: 120px;
    width: 343px;
  }
}

.createCardContent {
  margin: auto;
  text-align: center;
}

.cardText {
  color: $gray600v1;
  font-weight: 600;
}

.cardContainer {
  border-radius: 12px;
  width: 352px;
  background-color: white;
  border-color: $neutral500;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 343px;
  }
}

.cardHeader {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: $neutral500;
  gap: 16px;
  flex-grow: 1;
}

.cardBody {
  padding: 24px 20px;
  border-bottom: 1px solid;
  border-color: $neutral500;
  color: $gray50v1;
}

.cardBodyRow {
  display: flex;
  gap: 8px;
  align-items: center;
}

.cardFooter {
  padding: 8px 8px;
  display: flex;
  justify-content: flex-start;
}

.customType {
  background-color: $primary25;
  color: $primary600;
  font-weight: 600;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 128px;
  height: 28px;
  font-size: 14px;
  text-align: center;
}

.standardType {
  background-color: $neutral100;
  color: $gray100v1;
  font-weight: 600;
  width: 110px;
  padding: 4px 10px;
  border-radius: 128px;
  height: 28px;
  font-size: 14px;
  text-align: center;
}

@import '../../../../variables.module.scss';

.overlay {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  background-color: $white;
  padding: 12px 16px;
  border: 1px solid $borderSoft200;
  border-radius: 4px;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  display: flex;
  gap: 12px;
  align-items: center;
  color: $textStrong950;
}

.countItem {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.deployedIcon {
  color: $blue500;
}

.standbyIcon {
  color: $gray400;
}

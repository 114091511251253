@import '../../../../variables.module.scss';

.popover {
  padding: 8px;
  margin: -24px;
  user-select: none;
}

.popoverFilterItem {
  min-width: 160px;
  padding: 8px;
}

.popoverFilterLabel {
  color: $textStrong900;
}

.popoverSortItem {
  min-width: 240px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: $textStrong900;
}

.popoverSelectedItem {
  color: $primaryBase;
}

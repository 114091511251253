@import '../../../variables.module.scss';

.navBar {
  display: flex;
  background-color: white;
  z-index: 2;

  @include desktop {
    position: fixed;
    padding-top: 28px;
    padding-bottom: 24px;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 32px;
    width: 280px;
    min-width: 180px;
    height: 100vh;
    border-right: 1px solid $neutral700;
  }

  @include mobile {
    justify-content: space-between;
    align-items: center;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
}

.slideoutHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 13px;
}

.slideoutLogo {
  margin: 2px 0;
  margin-left: 16px;
  padding: 16px 0;
  padding-left: 16px;
  display: flex;
  align-items: center;
  color: $primary600;
}

.slideoutCloseButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: $gray50v1;
}

.mobileNavBar {
  @include desktop {
    display: none;
  }
}

.desktopNavBar {
  @include mobile {
    display: none;
  }
}

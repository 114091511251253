@import '../../../variables.module.scss';

.wrapper {
  display: inline-block;
}

.content {
  border: 1px solid $neutral700;
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.green {
  color: $success600;
}

.gray {
  color: $fadedBase;
}

.blue {
  color: $primaryBase;
}

.orange {
  color: $warningBase;
}

.purple {
  color: $infoBase;
}

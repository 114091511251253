@import '../../variables.module.scss';

.filters {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @include mobile {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}

.detailsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.searchInput {
  width: 150px;
}

@import '../../variables.module.scss';

.noticeBody {
  display: flex;
  justify-content: center;
  margin: 12px;
}

.tableContainer {
  border-radius: 4px;
  border: 1px solid $neutral700;
  background-color: white;
}

.tableContentWrapper {
  @include mobile {
    overflow-x: auto;
    width: auto;
    /* ToDo: set width back to 100% */

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.tableContentWrapper::-webkit-scrollbar {
  display: none;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

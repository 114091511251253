@import '../../../variables.module.scss';

.navItem {
  height: 46px;
  padding: 0 24px;
  cursor: pointer;
  font-size: 15px;
  color: $gray50v1;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.navItem:hover {
  color: $primary600;
}

.activeItem {
  color: $primary600;
  background-color: $primary25;
}

.navIcon {
  min-width: 22px;
}

@import '../../../../variables.module.scss';

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  height: 100%;
  width: 384px;
  padding: 32px;
  border-right: 1px solid $borderSoft200;
  overflow-y: auto;
}

.accordion {
  position: relative;
}

.accordionPanel {
  border-top: none;
  margin: 0;
  padding: 0;
}

.accordionContainer {
  width: 100%;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
}

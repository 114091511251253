@import '../../../variables.module.scss';

.disabled {
  color: $textDisabled300;
  background-color: $bgWeak50;
}

.button {
  border-radius: 4px;
  border: 1px solid transparent;
  text-decoration: none;
}

.md {
  padding: 10px;
}

.sm {
  padding: 8px;
}

.xs {
  padding: 6px;
}

.xxs {
  padding: 4px 6px;
}

.stroke {
  background-color: $white;
}

.ghost {
  background-color: transparent;
}

.primary {
  &:active {
    box-shadow: 0px 0px 0px 3px rgba(41, 112, 255, 0.08);
  }

  &.filled {
    background-color: $primaryBase;
    color: $white;

    &:hover {
      background-color: $primaryDark;
    }
  }

  &.stroke {
    border: 1px solid $primaryBase;
    color: $primaryBase;

    &:hover {
      background-color: $primaryLighter;
    }

    &:active {
      background-color: $white;
    }
  }

  &.ghost {
    color: $primaryBase;

    &:hover {
      background-color: $primaryLighter;
    }

    &:active {
      border: 1px solid $primaryBase;
      background-color: $white;
    }
  }
}

.neutral {
  &:active {
    box-shadow: 0px 0px 0px 3px rgba(113, 119, 132, 0.08);
  }

  &.filled {
    background-color: $bgStrong900;
    color: $white;

    &:hover {
      background-color: $bgSurface800;
    }
  }

  &.stroke {
    border: 1px solid $borderSoft200;
    color: $textSub600;

    &:hover {
      background-color: $bgWeak50;
    }

    &:active {
      border: 1px solid $borderStrong900;
      background-color: $white;
    }
  }

  &.ghost {
    color: $textSub600;

    &:hover {
      background-color: $bgWeak50;
    }

    &:active {
      border: 1px solid $borderStrong900;
      background-color: $white;
    }
  }
}

.error {
  &:active {
    box-shadow: 0px 0px 0px 3px rgba(251, 55, 72, 0.08);
  }

  &.filled {
    background-color: $errorBase;
    color: $white;

    &:hover {
      background-color: $errorDark;
    }
  }

  &.stroke {
    border: 1px solid $errorBase;
    color: $errorBase;

    &:hover {
      background-color: $errorLighter;
    }

    &:active {
      border: 1px solid $errorBase;
      background-color: $white;
    }
  }

  &.ghost {
    color: $errorBase;

    &:hover {
      background-color: $errorLighter;
    }

    &:active {
      border: 1px solid $errorBase;
      background-color: $white;
    }
  }
}

@import '../../variables.module.scss';

.splash {
  background-color: $white;
  padding-top: 96px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  @include mobile {
    padding-top: 48px;
  }
}

.logoContainer {
  margin-bottom: 24px;
  color: $primary600;
}

.iconContainer {
  margin-bottom: 24px;
  color: $gray600v1;
  padding: 14px;
  border: 1px solid $neutral500;
  border-radius: 12px;
}

.cardContainer {
  max-width: 360px;
  @include mobile {
    max-width: 343px;
  }
}

.cardHeader {
  text-align: center;
  color: $gray600v1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  @include mobile {
    gap: 8px;
  }
}

.cardTitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  @include mobile {
    font-size: 24px;
    line-height: 32px;
  }
}

.cardSubtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.boldSubtitle {
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin formInputsBase {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 360px;
  @include mobile {
    width: 343px;
  }
}

.formInputs {
  @include formInputsBase;
  margin-bottom: 24px;
  gap: 32px;
}

.formInputsWithValidation {
  @include formInputsBase;
  margin-bottom: 8px;
  gap: 8px;
}

.textInputError {
  border-color: $error600;
}

.textInputErrMsg {
  color: $error600;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 4px;
}

.hidden {
  visibility: hidden;
}

.actionsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 24px;
}

.resendRow {
  display: flex;
  gap: 4px;
  color: $gray400;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 32px;
}

.checkboxLabel {
  color: $gray600v1;
  font-weight: 500;
}

.blueLink {
  color: $primary600;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.passwordRequirementsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  gap: 12px;
  margin-bottom: 24px;
}

.passwordRequirement {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.validIcon {
  color: $success700;
}

.invalidIcon {
  color: $neutral700;
}

.errorIcon {
  color: $error600;
}

.validText {
  color: $gray400;
}

.invalidText {
  color: $gray50v1;
}

.formButton {
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: stretch;
  margin-bottom: 32px;
}

.iconLink {
  color: $gray400;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  gap: 6px;
}

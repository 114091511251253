@import '../../../variables.module.scss';

.navBarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include mobile {
    padding: 16px;
    padding-right: 8px;
  }
}

.logoLink {
  @include desktop {
    margin-left: 28px;
  }

  @include mobile {
    margin-left: 16px;
  }
}

.slideoutToggle {
  padding: 8px;
  cursor: pointer;

  @include desktop {
    display: none;
  }
}
